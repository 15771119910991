<template>
    <div class="relative text-white-1">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-contact-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 pt-4" src="../../../assets/images/contact-us/pn-contactus-banner.png" alt="Contact Us Banner">
                <div class="flex flex-col mx-36 mobileL:mx-0 text-left mobileL:text-center z-10 max-w-xs lg:max-w-lg 2xl:max-w-xl p-0 mobileL:p-4 
                ml-6 mobileL:ml-12 lg:ml-32 xl:ml-40 2xl:ml-48 relative mt-8 mobileL:-mt-12">
                    <div class="relative inline-block">
                        <span class="pn-contact-bg-highlight absolute top-4 mobileL:top-8 lg:top-10 2xl:top-12 left-0 w-5/6 mobileL:w-full h-1/2 2xl:h-1/2 z-10"></span>
                        <img class="absolute -top-2 mobileL:-top-1 -left-1 mobileL:left-2 lg:left-4 2xl:left-2 2xl:top-1 w-4 lg:w-5 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                        <div class="text-4xl md:text-5xl lg:text-7xl 2xl:text-8xl font-semibold relative z-20">
                            <h1 class="font-kannada font-semibold">Contact Us</h1>
                        </div>
                        <img class="absolute -top-4 mobileL:top-0 lg:-top-4 right-2 mobileL:-right-16 lg:-right-20 z-0 lg:w-28 2xl:w-auto w-16 mobileL:w-20" src="../../../assets/images/home-page/sketch-line.png" alt="">
                    </div>
                    <p class="font-monsterrat mobileL:font-semibold 2xl:mt-6 text-sm mobileL:text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl -mx-20 ml-0 mobileL:-mx-12 2xl:-mx-24 leading-snug">
                        Have a questions or comments? We will get back to you as soon as we can! 
                    </p>
                </div>
                <img class="absolute hidden mobileL:block top-12 left-12 lg:top-24 lg:left-24 object-cover z-5 pt-4 w-20 lg:w-32" src="../../../assets/images/contact-us/pn-leaf.png" alt="leaf">
                <img class="absolute hidden mobileL:block bottom-16 left-64 lg:bottom-24 lg:left-100 2xl:left-120 object-cover z-5 pt-4 w-24 lg:w-36" src="../../../assets/images/contact-us/pn-leaf-2.png" alt="leaf">
            </div>
        </div>
        <div class="bg-green-15 py-6 mobileL:py-8 lg:py-12 2xl:py-24 text-white-1 relative">
            <div class="text-center flex flex-col items-center mb-4 relative">
                <div class="relative inline-block">
                    <span class="pn-help-highlight absolute top-2 mobileL:top-4 lg:top-8 2xl:top-8 left-0 w-full h-5 mobileL:h-1/5 z-10"></span>
                    <div class="text-xl mobileL:text-4xl lg:text-6xl 2xl:text-7xl font-semibold pb-4 mobileL:pb-6 relative z-20">
                        <h2 class="font-kannada font-semibold">We are always ready to help!</h2>
                    </div>
                </div>
                <div>
                    <p class="font-monsterrat mx-8 mobileL:font-semibold text-sm font-montserrat mobileL:text-sm lg:text-xl xl:text-2xl 2xl:text-3xl hidden mobileL:block">
                        You may email us at <a class="pn-contact-gmail underline" href="mailto:customercare@lay-bare.com">
                        customercare@lay-bare.com</a> and <br>
                        we’ll get in touch with you from Mondays to Fridays, 9AM-6PM.<br><br>
                        Keep in touch in our social media platforms!
                    </p>
                    <p class="font-monsterrat mx-8 mobileL:font-semibold text-sm font-montserrat mobileL:text-sm lg:text-xl xl:text-2xl 2xl:text-3xl block mobileL:hidden">
                        You may email us at <a class="pn-contact-gmail underline" href="mailto:customercare@lay-bare.com">
                        customercare@lay-bare.com</a> and
                        we’ll get in touch with you from Mondays to Fridays, 9AM-6PM.<br><br>
                        <span class="font-semibold">Keep in touch in our social media platforms!</span>
                    </p>
                </div>
                <div class="flex mt-4">
                    <ol class="flex gap-3 mobileL:gap-8 2xl:gap-10">
                        <li><a href="https://www.facebook.com/OfficialPassionails">
                            <img class="w-8 mobileL:w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/pn-fb.png" alt="">
                        </a></li>
                        <li><a href="https://www.instagram.com/officialpassionails/">
                            <img class="w-8 mobileL:w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/pn-ig.png" alt="">
                        </a></li>
                        <li><a href="https://twitter.com/laybareph">
                            <img class="w-8 mobileL:w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/pn-twt.png" alt="">
                        </a></li>
                        <li><a href="https://www.tiktok.com/@officiallaybare">
                            <img class="w-8 mobileL:w-12 lg:w-16 2xl:w-24 2xl:p-0.5" src="../../../assets/images/contact-us/pn-tiktok.png" alt="">
                        </a></li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/contact-us')
    },
}
</script>

<style>
.pn-contact-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #dd9d9e 0%, #dd9d9e 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-contact-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-contact-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-contact-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-contact-banner-title {
    color: #FFF;
    font-style: normal;
}

.pn-contact-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

.pn-contact-gmail {
    font-weight: 600;
}

.pn-help-highlight {
    position: absolute;
    background: #E1B6EB;
    left: 0px;
}
</style>